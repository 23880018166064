export const organization = {
  NAME: 'name__icontains',
};

export const user = {
  USERNAME: 'user__username__icontains',
  LAST_NAME: 'user__last_name__icontains',
  FIRST_NAME: 'user__first_name__icontains',
  ORGANIZATION_NAME: 'organization__name__iexact',
  USE_EMAIL_NOTIFICATION: 'use_email_notification',
  TASK_REVIEWER: 'only_task_reviewers_of_related_organization',
  TASK_CREATOR: 'only_task_creators_of_related_organization',
};

export const rule = {
  APPROVED: 'approved',
  ON_APPROVAL: 'on_approval',
  DISAPPROVED: 'disapproved',
  DESCRIPTION: 'description__icontains',
  ON_TESTING: 'on_testing',
  SYSTEM_RULE_ID: 'system_rule_id__exact',
  SYSTEM_RULE_CODE: 'system_rule_code__icontains',
  SYSTEM_RULE_DESCRIPTION: 'system_rule_description__icontains',
  CLIENT_ORGANIZATION_NAME: 'client_organization__name',

  RULE_CODE: 'rule__code__icontains',
  RULE_DESCRIPTION: 'rule__description__icontains',

  TYPE_NAME: 'type_name__exact',
  SECTION_NAME: 'section_name__exact',
  CODE: 'code__icontains',
  HUMAN_READABLE_RULE_NAME: 'human_readable_rule_name__icontains',
  PROBABILITY_NAME: 'probability__name',
  CASE_ID: 'case_id__iexact',
  IS_SPECIFIC: 'is_specific',
};

export const lpu = {
  ID: 'id__in',
  NAME: 'name__icontains',
  CODE: 'code__icontains',
  IS_GREEN_CHANNEL: 'is_green_channel',
};

export const city = {
  ID: 'id__in',
  NAME: 'name__icontains',
  TASK_ID: 'task_id__iexact',
};

export const productType = {
  ID: 'id__in',
};

export const normativeAct = {
  DIAGNOSIS_CODE: 'diagnosis_code__icontains',
  NAME_OF_ORDER: 'name_of_order__icontains',
  CLINICAL_DECISION_NAME: 'clinical_decision_name__icontains',
};

export const normativeActAnalysis = {
  NUM: 'num__icontains',
  TEXT: 'text__icontains',
  DATE: 'date__icontains',
  GROUP: 'group__icontains',
  MKB_CLASS: 'mkb_class__icontains',
  MKB_CODE: 'mkb_code__icontains',
  MKB_NAME: 'mkb_name__icontains',
  SERVICES: 'services__icontains',
  ADDITIONAL_INFO: 'additional_info__icontains',
  EVIDENCE_LEVEL: 'evidence_level__icontains',
  RECOMMENDED: 'recommended__iexact',
  SURGICAL: 'surgical__icontains',
};

export const defect = {
  CODE: 'code__icontains',
  DESCRIPTION: 'description__icontains',
};

export const reason = {
  CODE: 'code',
  NUM: 'num__iexact',
  DESCRIPTION: 'description__icontains',
};

export const task = {
  STATUS: 'status__in',
  ID: 'ids',
  NAME: 'name__icontains',
  CREATOR: 'creators',
  MIN_DATE: 'min_create_date',
  MAX_DATE: 'max_create_date',
  CASE_ROW_LPU_ID: 'case__row__lpu__id__in',
  CASE_ROW_DIVISION_ID: 'case__row__division__id__in',
  CASE_ROW_PRODUCT_TYPE_ID: 'case__row__product_type__id__in',
  REVIEWER: 'reviewers',
  BILL: 'case__row__bill__icontains',
  HISTORICAL: 'historical',
  IS_FRAUD: 'has_fraud_result',
};

export const caseFilter = {
  PRODUCT_TYPE: 'product_type__in',
  SERVICE_ID: 'service_id__in',
  DIAGNOSIS_ID: 'diagnosis_id__in',
  CLIENT_RULE_ID: 'client_rule_id__in',
  LPU_ID: 'lpu_id__in',
  CITY_ID: 'city_id__in',
  BILL: 'bill__icontains',
  INSURANCE_POLICY_CODE: 'insurance_policy_code__icontains',
  INSURERS_ID: 'insurers_id__in',
  IS_FRAUD: 'has_fraud_result',
};

export const complaintFilter = {
  ID: 'id',
  STATUS: 'status',
  AUTHOR: 'author',
  MIN_CREATE_DATE: 'min_create_date',
  MAX_CREATE_DATE: 'max_create_date',
  HAS_UNREAD_MESSAGES: 'has_unread_messages',
  ORGANIZATION: 'organization_name',
};
